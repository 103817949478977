import { WOW } from "../../node_modules/wowjs/dist/wow.min.js";

(function($) {
    
    let wow = new WOW(
    {
      mobile:       false,       // default
    }
    )
    wow.init();
    
	$(document).ready(function() {
        
        $('#burger-menu').click(function(){

            $(this).toggleClass('close');
            $('#menu-navigation-principal').toggleClass('show');
            wow.sync();
        });
        
        $(window).resize(function(){
            
            if($(window).width()<910){
                $('#menu-navigation-principal').addClass('mobile');
            }
            else{
                $('#menu-navigation-principal').removeClass('mobile');
            }
            
            $('.blog .news__img-container').css('max-height', $('.blog .news__img-container img').height());
            $('.home .img-container').css('max-height', $('.home .img-container img').height());
            
            var padding_right = $('.home .news .news__content--title').css('padding-right');
            $('.home .news .news__content--title h3').css('padding-right', padding_right);
            
            wow.sync();
        }).resize();
        
        $('.menu-item-has-children > a').click(function(e){
            
            if($(window).width()<910){
                e.preventDefault();
            }
            wow.sync();
        });
        
        $('.menu-item-has-children').click(function(){
           
            if($(window).width()<910){
                
                if($(this).hasClass('show_sub')){
                    $(this).removeClass('show_sub');
                }
                else{
                    $('.menu-item-has-children').removeClass('show_sub');
                    $(this).addClass('show_sub');   
                }
            }
            wow.sync();
        });
    });
})(jQuery);
